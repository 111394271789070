import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../components/seo";
import Skel from "../components/skel";

const AboutUsPage = () => (
  <Skel>
    <SEO title="About Us" />
    <section
      className="section bg-center bg-cover effect-section"
      style={{ paddingTop: "50px", paddingBottom: "50px" }}
    >
      <div className="mask theme-bg opacity-8"></div>
      <Container>
        <Row>
          <Col lg={12} className="text-center">
            <h2 className="white-color h1 m-20px-b">About Us</h2>
          </Col>
        </Row>
      </Container>
    </section>
    <main>
      <Container className="p-3">
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <h3>Who We Are</h3>
            <p>
              Taplist.io was created by our founder Mike in 2016 after he became
              frustrated with the low quality and high cost of TV menu systems
              in the market. A veteran of the craft beer and beverage space for
              over 15 years, Mike has been a technologist and beer lover all of
              his adult life.
            </p>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col md={{ span: 8, offset: 2 }}>
            <h3>Our Values</h3>
            <p>
              <em>
                We built Taplist.io upon a bedrock of core values&mdash;values we
                hope you will see and feel in all that we do.
              </em>
            </p>
            <h5>A Quality Product for Everyone</h5>
            <p>
              We believe everybody should have access to a great product at a
              reasonable price. You shouldn't need a 5-figure budget, or a staff
              of designers and engineers, to have a beautiful menu.
            </p>
            <h5>It Should &quot;Just Work&quot;</h5>
            <p>
              You have bigger problems and better ways to spend your time than
              wrestling with technology. We strive for a product that always
              works, and works well, whenever you need it.
            </p>
            <h5>We Think Like Our Customers</h5>
            <p>
              We're beer people. We believe the best possible product comes from
              people who think like their customers.
            </p>
            <h5>Little Company, Big Flexibility</h5>
            <p>
              We're the &quot;little guys&quot; in this space&mdash;and we love it.
              We do things the others can't, and move quickly to surprise and delight
              our customers.
            </p>
          </Col>
        </Row>
      </Container>
    </main>
  </Skel>
);

export default AboutUsPage;
